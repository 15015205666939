<script setup lang="ts">
const { open } = useAppModals();
const { isDesktop } = useDevice();
const loginGuard = useLoginGuard();
const { seasonInitData } = useSeasonsData({ immediate: false });

const handleOpenSeason = () =>
	loginGuard({
		success: () => {
			open("LazyOModalSeason");

			dispatchGAEvent({
				event: "click_button",
				button_name: "season_popup",
				location: "aside_widget"
			});
		},
		fail: () => {
			open("LazyOModalSignup", { location: "sidebar_season" });
		}
	});
</script>

<template>
	<div v-if="isDesktop" class="aside" @click="handleOpenSeason">
		<NuxtImg
			src="/nuxt-img/season/aside-widget.png"
			class="image"
			format="avif"
			alt="tower element"
			width="192"
			height="192"
			loading="lazy"
		/>
		<AText v-if="!seasonInitData?.isAvailable" class="text-carabanchel text-tlalnepantla label" :modifiers="['bold']">
			1
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.aside {
	position: fixed;
	right: -50px;
	bottom: 320px;
	z-index: 1000;
	cursor: pointer;
}

.image {
	width: 96px;
	height: 96px;
}

.label {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--сirebon);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 8%;
	left: 6%;
}
</style>
